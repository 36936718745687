import * as React from 'react';
import './App.css';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


const headings = {
  fontWeight: 600,
  letterSpacing: '0.5rem',
  lineHeight: '1.3',
}
const theme = createTheme({
  palette: {
    neutral: {
      main: '#fff',
      contrastText: '#777',
    },
  },
  typography: {
    fontFamily: [
      'Source Sans Pro, sans-serif',
      // 'BlinkMacSystemFont',
      // '"Segoe UI"',
      // 'Roboto',
      // '"Helvetica Neue"',
      // 'Arial',
      'sans-serif',
      // '"Apple Color Emoji"',
      // '"Segoe UI Emoji"',
      // '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      ...headings,
      fontSize: '3rem'
    },
    h2: {
     ...headings,
     fontSize: '2rem'
    },
    h3: {
      ...headings
    },
    h4: {
      ...headings
    },
    h5: {
      ...headings
    },
    body1: {
      fontWeight: '300',
    },
    subtitle1: {
      fontSize: '1.5rem'
    }
  }
});

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 600,
//   maxWidth: '100%',
//   bgcolor: '#262222',
//   border: '1px solid #0a0808',
//   boxShadow: 12,
//   textAlign: 'center',
//   p: 4,

// };

function App() {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const toggleAboutDialog = () => {
    setOpen(!open);
  };
  return (
    <ThemeProvider theme={theme}>
      <Grid container alignItems="center" justifyContent="center" direction="column">
        <Grid item className="heroContainer">
          <Grid item className="heroText">
            <Typography variant="h2" component="h1" gutterBottom>Vince Montalbano</Typography>
            <Typography variant="subtitle1" component="div">
              Partner at BCG X
            </Typography>
          </Grid>
          <Grid item className="buttonAbout">
              <Button onClick={toggleAboutDialog} size="large" color="neutral" variant="contained">About Vince</Button>
              <Modal
                open={open}
                onClose={handleClose}
              >
                <Box className="aboutModal" sx={{ boxShadow: 25 }}>
                  <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[100],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography id="modal-modal-title" variant="h5" gutterBottom component="h3" sx={{ textAlign:'left', textTransform:'uppercase', borderBottom:'1px solid #fff', width:'fit-content'}}>
                    About
                  </Typography>
                  <Box>
                       <img alt="Vince" src="/vince.jpg" />
                  </Box>
                  <Typography variant="body1" component="div">
                    I am a Partner at BCG X, and work as a General Manager with deep Engineering and Product expertise, focused on building new ventures in partnership with world-class corporations. I have deep knowledge within the Financial Services & Energy Industries. I believe that lasting businesses are built with strong teams at the foundation, a clear mission statement to guide them, and intelligently use technology as a catalyst to the business.
                  </Typography>
                </Box>
              </Modal>
            </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default App;
